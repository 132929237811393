import { Routes, Route, Navigate } from 'react-router-dom';
import { useAppSelector } from '../hooks/redux';

import ProtectedRoute from './ProtectedRoute';
import RouteChange from './RouteChange';

import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Customers from '../pages/Customers';
import Customer from '../pages/Customer';
import Model from '../pages/Model';
import Prompts from '../pages/Prompts';

import Partners from '../pages/Partners';
import Partner from '../pages/Partner';

import Datastream from '../pages/Datastream';
import Users from '../pages/Users';
import ResetPassword from '../pages/ResetPassword';
import SuperProtectedRoute from './SuperProtectedRoutes';
import User from '../pages/User';
const UserRoutes = (props?: any) => {
  const user = useAppSelector((state) => state.user);

  return (
    <>
      <RouteChange />
      <Routes>
        <Route path="/login" element={<Login {...props} />}></Route>
        <Route path="/reset-password" element={<ResetPassword {...props} />}></Route>

        {/* Routes you need to be logged in to see */}
        <Route element={<ProtectedRoute redirectPath="/login" />}>
          <Route path="/" element={<Navigate to={'/dashboard'} />} />
          <Route path="/dashboard" element={<Dashboard {...props} />} />
          <Route path="/customers" element={<Customers {...props} />} />
          <Route path="/prompts" element={<Prompts {...props} />} />
          <Route path="/customers/:org_id" element={<Customer {...props} />} />
          <Route path="/customers/:org_id/models/:model_id" element={<Model {...props} />} />
          <Route path="/customers/:org_id/datastream/:datastream_id" element={<Datastream {...props} />} />
          <Route path="/users" element={<Users {...props} />} />
          <Route path="/users/:uid" element={<User {...props} />} />

          {/* Routes you need to be logged in AND have super_admin to see */}
          <Route element={<SuperProtectedRoute redirectPath="/dashboard" />}>
            <Route path="/partners" element={<Partners {...props} />} />
            <Route path="/partners/:partner_id" element={<Partner {...props} />} />
          </Route>
          {/* <Route path="/customers/:org_id/users" element={<Users {...props} />} /> */}
        </Route>
      </Routes>
    </>
  );
};

export default UserRoutes;
