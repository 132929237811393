import axios from 'axios';
import { ACCESS_TOKEN, API_ENDPOINTS } from '../utils/constants';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { useAppSelector } from '../hooks/redux';
import { authDestroy } from '../utils/authentication';
interface GetModelAction extends AnyAction {
  payload: Promise<void>;
}

const getPartnerUsers = (in_params: any, dispatch: any, user: any): ThunkAction<Promise<void>, any, any, GetModelAction> => {
  return async function getPartnerUsersThunk() {
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    try {
      const params: any = {
        params: in_params,
        uid: user?.id,
        org_id: user?.org_id,
      };
      const response = await axios.get(`${API_ENDPOINTS.KARLA_FLASK_API}/partners/get/users`, {
        params,
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      });

      if (response.data && response.data.type === 'success') {
        dispatch({ type: 'SET_ORGANIZATION_USERS', payload: response?.data?.users });
      }

      if (response.data && response.data.type === 'error') {
        dispatch({ type: 'DISPLAY_ERROR', payload: { reason: response?.data?.reason } });
      }
    } catch (error: any) {
      if (error?.response && error?.response?.status === 401) {
        // Handling 401 error
        authDestroy();
      } else {
        // Handling other errors
        dispatch({ type: 'DISPLAY_ERROR', payload: { reason: error?.message } });
      }
    }

    dispatch({ type: 'SET_IS_LOADING', payload: false });
  };
};

export default getPartnerUsers;
