import React from 'react';
import LeftPanel from '../components/sections/left-panel';
import ContentPanel from '../components/sections/content-panel';
import PartnerList from '../components/partner/PartnerList';
import AddPartner from '../components/partner/AddPartner';
import Modal from '../components/modals/Modal';

function Partners() {
  const [addNewPartner, setAddNewPartner] = React.useState(false);
  return (
    <div className="app customers">
      <LeftPanel />

      <ContentPanel title={'Partners'}>
        <Modal buttonText={'Add new partner'} children={<AddPartner setAddNewPartner={setAddNewPartner} />} onclose={setAddNewPartner} title="Add new partner" size="tall" />
        <PartnerList addNewPartner={addNewPartner} />
      </ContentPanel>
    </div>
  );
}

export default Partners;
