import axios from 'axios';
import { ACCESS_TOKEN, API_ENDPOINTS } from '../utils/constants';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { useAppSelector } from '../hooks/redux';
import { authDestroy } from '../utils/authentication';
interface GetOrganizationsAction extends AnyAction {
  payload: Promise<void>;
}

/**
 * Fetches organizations from the API and dispatches actions to update the Redux store.
 *
 * @param {any} params - The parameters to be sent with the API request.
 * @param {any} dispatch - The Redux dispatch function.
 * @returns {ThunkAction<Promise<void>, any, any, GetOrganizationsAction>} A thunk action that fetches organizations.
 */
const getOrganizations = (in_params: any, dispatch: any, user: any): ThunkAction<Promise<void>, any, any, GetOrganizationsAction> => {
  return async function getOrganizationsThunk() {
    dispatch({ type: 'SET_IS_LOADING', payload: true });

    try {
      const params = {
        params: {
          ...in_params,
        },
        uid: user?.id,
        org_id: user?.org_id,
      };
      const response = await axios.get(`${API_ENDPOINTS.KARLA_FLASK_API}/karla/operations/organizations/get`, {
        params,
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      });

      if (response.data && response.data.type === 'success') {
        if (response.data.response instanceof Array) {
          dispatch({ type: 'SET_ORGANIZATIONS', payload: response?.data?.response });
        } else {
          dispatch({ type: 'SET_ORGANIZATION', payload: response?.data?.response });
        }
      }

      if (response.data && response.data.type === 'error') {
        dispatch({ type: 'DISPLAY_ERROR', payload: { reason: response.data.reason } });
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        // Handling 401 error
        authDestroy();
      } else {
        // Handling other errors
        dispatch({ type: 'DISPLAY_ERROR', payload: { reason: error.message } });
      }
    }

    dispatch({ type: 'SET_IS_LOADING', payload: false });
  };
};

export default getOrganizations;
