import React, { useEffect, useState } from 'react';
import LeftPanel from '../components/sections/left-panel';
import UserList from '../components/Users/UserList';
import getOrganizationUsers from '../api/get-user-from-org';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import PartnerUsers from '../components/partner/tabs/PartnerUsers';
import getPartnerUsers from '../api/get-partner-users';
import ContentPanel from '../components/sections/content-panel';
import Modal from '../components/modals/Modal';
import AddUserToPartner from '../components/partner/AddUserToPartner';
import getPartner from '../api/get-partner';

const Users = () => {
  const dispatch = useAppDispatch();
  const partner = useAppSelector((state) => state.partner);
  const user = useAppSelector((state) => state.user);
  const partnerUsers = useAppSelector((state) => state.organization_users);
  const [hasPartner, setHasPartner] = useState(false);

  useEffect(() => {
    if (user?.partner_id === undefined) {
      setHasPartner(false);
    } else {
      setHasPartner(true);
    }

    if (partner === false && hasPartner) {
      const getPartnerThunk = getPartner({ partner_id: user?.partner_id }, dispatch, user);
      dispatch(getPartnerThunk);
    }

    if (partner !== false && hasPartner) {
      const getPartnerUsersThunk = getOrganizationUsers({ org_id: partner.id }, dispatch, user);
      dispatch(getPartnerUsersThunk);
    }
  }, [partner, hasPartner]);

  return (
    <div className="app customers">
      <LeftPanel />
      <ContentPanel title={'Partner: ' + partner?.metadata?.company_name + ' (' + partner?.id + ')'}>
        {!hasPartner ? (
          <NoPartnerAttached />
        ) : (
          <>
            <Modal buttonText={'Invite user'} children={<AddUserToPartner />} title="invite user" description="invite user here" size="slim" />
            <PartnerUsers partnerUsers={partnerUsers} />
          </>
        )}
      </ContentPanel>
    </div>
  );
};

export default Users;

function NoPartnerAttached() {
  return <h3 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>No partner detected</h3>;
}
