import Cookies from 'js-cookie';
import axios from 'axios';
import { ACCESS_TOKEN, APP_ROOT, API_ENDPOINTS } from '../utils/constants';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import getUser from './get-user';
import { Navigate } from 'react-router-dom';
import { authDestroy } from '../utils/authentication';

type Response = {
  type: string;
  response: {
    reason: string;
    access_token?: any;
    uid: string;
    org_id: string;
  };
};

interface PromptQuestionAction extends AnyAction {
  payload: Promise<void>;
}

const login = (params: any, dispatch: any): ThunkAction<Promise<void>, any, any, PromptQuestionAction> => {
  return async function loginThunk() {
    dispatch({ type: 'SET_IS_LOADING', payload: true });

    try {
      const response = await axios.post<Response>(`${API_ENDPOINTS.KARLA_FLASK_API}/karla/operations/login`, {
        params: params,
      });
      if (response.data && response.data.type === 'success') {
        const uid = response?.data?.response?.uid;
        const org_id = response?.data?.response?.org_id;
        const user_params = {
          params: {
            uid: uid,
          },
          org_id: org_id,
          uid: uid,
        };
        const response_user = await axios.get(`${API_ENDPOINTS.KARLA_FLASK_API}/user/get`, {
          params: user_params,
          headers: {
            Authorization: `Bearer ${response?.data?.response?.access_token}`,
          },
        });
        if (response_user.data && response_user.data.type === 'success') {
          dispatch({ type: 'SET_USER', payload: response_user.data.data });
          Cookies.set('karla_authorization', response?.data?.response?.access_token, { expires: 7 });
          window.location.href = `${APP_ROOT}/customers`;
          // <Navigate to={`/dashboard`} /> // doesn't work for some reason
          // <Navigate to={`${APP_ROOT}/dashboard`} /> // also tried this
        }
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        // Handling 401 error
        dispatch({ type: 'DISPLAY_ERROR', payload: { reason: error.response.data.response.reason } });
      } else {
        // Handling other errors
        dispatch({ type: 'DISPLAY_ERROR', payload: { reason: error.message } });
      }
    }

    dispatch({ type: 'SET_IS_LOADING', payload: false });
  };
};

export default login;
