import { Box, Card, FormControl, Link, TextField, Typography } from '@mui/material';
import React from 'react';
import PrimaryButton from '../button/PrimaryButton';
import { ReactComponent as KarlaKIcon } from '../../assets/img/karla-k-icon.svg';
import { APP_ROOT } from '../../utils/constants';

interface Props {
  userCredentials: {
    email: string;
    password: string;
  };
  setUserCredentials: (userCredentials: { email: string; password: string }) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}
const LoginForm = ({ userCredentials, setUserCredentials, handleSubmit }: Props) => {
  const ResetPassword: React.FC = () => {
    return (
      <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <Typography sx={{ color: '#333', fontSize: '14px' }}>
          <Link
            href={`${APP_ROOT}/reset-password?e=${userCredentials.email}`}
            underline="hover"
            sx={{
              fontWeight: 'bold',
              fontSize: '14px',
              color: '#1976d2',
            }}
          >
            Reset password
          </Link>
        </Typography>
      </div>
    );
  };
  return (
    <Card sx={{ p: 2.5 }}>
      <Box className="auth__container-logo">
        <KarlaKIcon />
      </Box>

      <Typography className="auth__container-title" variant="h1">
        {'Welcome back!'}
      </Typography>

      <form className="auth__container-form form" onSubmit={handleSubmit}>
        <FormControl className="form__fields">
          <TextField
            label="E-mail"
            value={userCredentials.email}
            variant="outlined"
            autoComplete="new-email"
            key={'email-input'}
            onChange={(event) => {
              const updatedCredentials = { ...userCredentials, email: event?.target?.value };
              setUserCredentials(updatedCredentials);
            }}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            key={'password-input'}
            autoComplete="new-password"
            value={userCredentials.password}
            onChange={(event) => {
              const updatedCredentials = { ...userCredentials, password: event?.target?.value };
              setUserCredentials(updatedCredentials);
            }}
          />

          <PrimaryButton text="Login" handleType="submit" />
          <ResetPassword />
        </FormControl>
      </form>
    </Card>
  );
};

export default LoginForm;
