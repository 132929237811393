import React from 'react';
import { useAppSelector } from '../../../hooks/redux';

function PartnerGeneral() {
  const partner = useAppSelector((state) => state.partner);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '20rem', justifyContent: 'space-between' }}>
      <p>Partner Name: {partner?.metadata?.company_name}</p>
      <p>Partner ID: {partner?.id}</p>
      <p>Created at: {partner.created}</p>
    </div>
  );
}

export default PartnerGeneral;
