import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChangeRole from './ChangeRole';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import updateUser from '../../api/update-user';

interface Role {
  key: string;
  value: string;
}

interface Props {
  item: any;
  roles?: Role[];
}

const PartnerUserListItem = ({ item, roles }: Props) => {
  const fullName = `${item?.firstname} ${item?.lastname}`;
  const allowed_roles = roles
    ? roles
    : [
        { key: 'Admin', value: 'admin' },
        { key: 'User', value: 'user' },
        { key: 'Partner', value: 'partner' },
      ];
  const [role, setRole] = React.useState(item?.role ?? '');
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const createdText = item?.created?.split(' ')[0];

  const handleChange = (event: any) => {
    setRole(event.target.value);
    const newUser = { ...item, role: event?.target?.value };
    const updateUserThunk = updateUser({ data: newUser, uid: newUser?.id }, dispatch, user);
    dispatch(updateUserThunk);
  };

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar>
          <AccountCircleIcon />
        </Avatar>
      </ListItemAvatar>
      <div style={{ width: '25%' }}>
        <h6 style={{ margin: 0 }}>{fullName}</h6>
        <div style={{ color: 'grey' }}>{item.email}</div>
      </div>
      <div style={{ width: '25%' }}>
        <ListItemText secondary={createdText ?? 'N/A'} />
      </div>
      <div style={{ width: '25%' }}>
        <ChangeRole role={role} options={allowed_roles} handleChange={handleChange} />
      </div>
      <div style={{ width: '25%' }}>
        <ListItemText secondary={item?.id ?? 'N/A'} />
      </div>
    </ListItem>
  );
};

export default PartnerUserListItem;
