import Button from '@mui/material/Button';

import { PrimaryButtonInterface } from '../../types/ButtonInterface';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const PrimaryButton = ({ text, role, icon, iconPosition = 'left', variant = 'primary', size = 'normal', position = 'left', disabled, handleType, className, handleClick, uploadHandleChange }: PrimaryButtonInterface) => {
  if (disabled) {
    return (
      <Button className={`btn ${variant}-btn ${className} size--${size} ${disabled ? 'btn--disabled' : ''} position--${position}`} variant="contained" disabled>
        {iconPosition && iconPosition === 'left' && icon}
        {text}
        {iconPosition && iconPosition === 'right' && icon}
      </Button>
    );
  }
  if (role == 'file') {
    return (
      <Button className={`size--${size} ${disabled ? 'btn--disabled' : ''}`} style={{ backgroundColor: '#6c64ff', color: 'white' }} component="label" role={'file'} variant="contained" tabIndex={-1} startIcon={<CloudUploadIcon />}>
        Upload File
        <VisuallyHiddenInput type="file" onChange={uploadHandleChange} />
      </Button>
    );
  }
  return (
    <Button className={`btn ${variant}-btn ${className} size--${size} ${disabled ? 'btn--disabled' : ''} position--${position}`} variant="contained" onClick={handleClick} type={handleType}>
      {iconPosition && iconPosition === 'left' && icon}
      {text}
      {iconPosition && iconPosition === 'right' && icon}
    </Button>
  );
};

export default PrimaryButton;
