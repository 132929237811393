import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../hooks/redux';

import LeftPanel from '../components/sections/left-panel';
import ContentPanel from '../components/sections/content-panel';

import getUser from '../api/get-user';

import getDatastream from '../api/get-datastream';
import BucketLink from '../components/datastreams/BucketLink';
import LoadingBackdrop from '../components/loading/LoadingBackdrop';
import getOrganizations from '../api/get-organizations';
import DatastreamOptions from '../components/datastreams/DatastreamOptions';
import { use } from 'i18next';
import getDatastreams from '../api/get-datastreams';

const Datastream = () => {
  const dispatch = useAppDispatch();
  const { org_id } = useParams();
  const { datastream_id } = useParams();
  const datastream = useAppSelector((state) => state.datastream);
  const datastreams = useAppSelector((state) => state.datastreams);
  const organization = useAppSelector((state) => state.organization);
  const organizations = useAppSelector((state) => state.organizations);
  const loading = useAppSelector((state) => state.loading);
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    if (datastream_id && org_id && !datastream) {
      const getDatastreamThunk = getDatastream({ datastream: datastream_id, org_id: org_id }, dispatch, user);

      dispatch(getDatastreamThunk);
    }
    if (!organizations) {
      const getOrganizationsThunk = getOrganizations({ org_id: org_id }, dispatch, user);
      dispatch(getOrganizationsThunk);
    }

    if (!datastreams && datastream_id) {
      const getDatastreamsThunk = getDatastreams({ organization_id: org_id }, dispatch, user);
      dispatch(getDatastreamsThunk);
    }
  }, [datastream_id, org_id]);

  useEffect(() => {
    if (datastreams !== false && datastream === false) {
      dispatch({ type: 'SET_DATASTREAM', payload: datastreams.find((ds: any) => ds.id === datastream_id) });
    }

    if (organizations !== false && organization === false) {
      dispatch({ type: 'SET_ORGANIZATION', payload: organizations.find((org: any) => org.id === org_id) });
    }
  }, [datastreams, organizations]);

  useEffect(() => {
    if (!organization) {
      if (organizations) {
        dispatch({ type: 'SET_ORGANIZATION', payload: organizations.find((org: any) => org.id === org_id) });
      }
    }
  }, [organizations]);

  return (
    <div className="app conversation">
      <LeftPanel />
      <ContentPanel title={organization?.metadata?.company_name}>
        <LoadingBackdrop open={loading} />
        <BucketLink />
        <DatastreamOptions />
      </ContentPanel>
    </div>
  );
};

export default Datastream;
