import { NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import CompareArrowsRoundedIcon from '@mui/icons-material/CompareArrowsRounded';
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import EqualizerRoundedIcon from '@mui/icons-material/EqualizerRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import RocketLaunchRoundedIcon from '@mui/icons-material/RocketLaunchRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import SmartToyRoundedIcon from '@mui/icons-material/SmartToyRounded';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { ACCESS_TOKEN } from '../../../utils/constants';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import jwtDecode from 'jwt-decode';

const Navigation = () => {
  const { t } = useTranslation();

  return (
    <nav className="left-panel__nav">
      <ul>
        {ACCESS_TOKEN && jwtDecode<any>(ACCESS_TOKEN).role === 'super_admin' && (
          <NavLink to="/dashboard">
            <li>
              <RocketLaunchRoundedIcon />
              <span>{'Dashboard'}</span>
              {/* <label className="coming-soon">{t('Coming')}</label> */}
            </li>
          </NavLink>
        )}
        <NavLink to="/customers">
          <li>
            <CorporateFareIcon />
            <span>{t('Organizations')}</span>
          </li>
        </NavLink>
        {ACCESS_TOKEN && jwtDecode<any>(ACCESS_TOKEN).role === 'super_admin' && (
          <>
            <NavLink to="/prompts">
              <li>
                <QuestionAnswerRoundedIcon />
                <span>{t('Prompts')}</span>
              </li>
            </NavLink>

            <NavLink to="/partners">
              <li>
                <Diversity3Icon />
                <span>{t('Partners')}</span>
              </li>
            </NavLink>
          </>
        )}

        <NavLink to="/users">
          <li>
            <PeopleAltIcon />
            <span>{t('Users')}</span>
          </li>
        </NavLink>
        {/* <NavLink to="/integrations">
          <li>
            <CompareArrowsRoundedIcon />
            <span>{t('Integrations')}</span>
            <label className="new-feature">{t('New!')}</label>
          </li>
        </NavLink> */}
        <NavLink className="left-panel__nav-end" to="/billing" style={{ pointerEvents: 'none' }}>
          <li>
            <PaymentsRoundedIcon />
            <span>{t('Billing')}</span>
            <label className="coming-soon">{t('Coming')}</label>
          </li>
        </NavLink>
        {/* <NavLink className="left-panel__nav-end" to="/help" style={{ pointerEvents: 'none' }}>
          <li>
            <SupportAgentRoundedIcon />
            <span>{t('Help')}</span>
            <label className="coming-soon">{t('Coming')}</label>
          </li>
        </NavLink> */}
      </ul>
    </nav>
  );
};

export default Navigation;
