import { Divider, Grid, List, ListItemText, ListSubheader } from '@mui/material';
import React from 'react';
import PartnerUserListItem from '../PartnerUserListItem';

interface Props {
  partnerUsers: any;
  roles?: any[];
}

const PartnerUsers = ({ partnerUsers, roles }: Props) => {
  return (
    <Grid item xs={12} md={6} className="model-list">
      <List>
        <ListSubheader className={'operations-list-header'}>
          <ListItemText primary={'User Name'} className="list-header-elem" />
          <ListItemText primary={'Created'} className="list-header-elem" />
          <ListItemText primary={'Role'} className="list-header-elem" />
          <ListItemText primary={'ID'} className="list-header-elem" />
        </ListSubheader>
        {partnerUsers &&
          partnerUsers?.map((item: any) => {
            return (
              <>
                <PartnerUserListItem item={item} roles={roles} />
                <Divider variant="middle" component="li" />
              </>
            );
          })}
      </List>
    </Grid>
  );
};

export default PartnerUsers;
