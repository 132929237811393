import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { Divider, Grid, List, ListItemText, ListSubheader } from '@mui/material';
import PartnerListItem from './PartnerListItem';
import getPartners from '../../api/get-partners';

interface Props {
  addNewPartner: boolean;
}

const PartnerList = ({ addNewPartner }: Props) => {
  const partners = useAppSelector((state) => state.partners);
  const partner = useAppSelector((state) => state.partner);
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (partners === false) {
      const getPartnersThunk = getPartners({}, dispatch, user);
      dispatch(getPartnersThunk);
    }
    if (partner !== false && !addNewPartner) {
      dispatch({ type: 'SET_PARTNER', payload: false });
    }
  }, [partners, partner, addNewPartner]);

  return (
    <Grid item xs={12} md={6} className="model-list">
      <List>
        <ListSubheader className={'operations-list-header'}>
          <ListItemText primary={'Partner Name'} className="list-header-elem" />
          <ListItemText primary={'Created on'} className="list-header-elem" />
          <ListItemText primary={'ID'} className="list-header-elem" />
        </ListSubheader>
        {partners &&
          partners.map((item: any) => {
            return (
              <>
                <PartnerListItem partner={item} />
                <Divider variant="middle" component="li" />
              </>
            );
          })}
      </List>
    </Grid>
  );
};

export default PartnerList;
