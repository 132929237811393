import React from 'react';
import PrimaryButton from '../button/PrimaryButton';
import { Autocomplete, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import inviteUser from '../../api/invite-user';

const AddUserToPartner = () => {
  const [inviteEmails, setInviteEmails] = React.useState<string[]>([]);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);

  const handleAddNew = () => {
    if (inviteEmails.length === 0) {
      return;
    }

    inviteEmails.map((email) => {
      const inviteUserThunk = inviteUser({ user_invite_email: email, partner_id: user.partner_id }, dispatch, user);
      dispatch(inviteUserThunk);
    });
  };

  const handleChange = (event: any, newValue: string[]) => {
    if (newValue.length > inviteEmails.length) {
      setInviteEmails(newValue);
    } else if (newValue.length < inviteEmails.length) {
      setInviteEmails(newValue);
    }
  };

  return (
    <>
      <Autocomplete multiple limitTags={2} id="multiple-attached-user-invites" options={[]} freeSolo value={inviteEmails} renderInput={(params) => <TextField {...params} label="Email" />} sx={{ width: '500px' }} onChange={handleChange} />

      <div onClick={handleAddNew}>
        <PrimaryButton text={'Invite'} />
      </div>
    </>
  );
};

export default AddUserToPartner;
