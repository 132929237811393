import { useState } from 'react';

import Box from '@mui/material/Box';
import MuiModal from '@mui/material/Modal';

import PrimaryButton from '../button/PrimaryButton';
import { Typography } from '@mui/material';
import IconButton from '../button/IconButton';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import './Modal.css';

interface ModalProps {
  buttonText: string;
  buttonClass?: string;
  title?: string;
  description?: string;
  size?: string;
  handleOpenChange?: any;
  handleCloseChange?: any;
  children: any;
  confirmation?: boolean;
  handleClick?: any;
  onclose?: (b: boolean) => void;
}
type ModalStyle = {
  position: string;
  top: string;
  left: string;
  transform: string;
  width: string;
  bgcolor: string;
  border: string;
  boxShadow: string | number;
  maxWidth: string | number;
  maxHeight: string | number;
  height: string | number;
  pt: number;
  px: number;
  pb: number;
};

const Modal = ({ buttonText, buttonClass = '', title = '', description = '', size = 'large', handleOpenChange = false, handleCloseChange = false, onclose = undefined, children, confirmation = false, handleClick }: ModalProps) => {
  const [open, setOpen] = useState(handleOpenChange);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    if (handleCloseChange) {
      handleCloseChange(false);
    }
    if (onclose) {
      onclose(false);
    }
  };

  const handleConfirm = () => {
    setOpen(false);
    if (handleClick) {
      handleClick();
    }
  };
  let modalClass = '';
  if (confirmation) {
    modalClass = 'confirmation__modal-box';
  } else {
    modalClass = 'basic__modal-box';
  }

  let style: ModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    maxWidth: 800,
    maxHeight: 1000,
    height: '1x',
    pt: 2,
    px: 4,
    pb: 3,
  };
  if (size === 'tall') {
    style = { ...style, maxWidth: 600, maxHeight: '1000px' };
    style = { ...style, height: '400pt' };
  }

  if (size === 'slim') {
    style = { ...style, maxWidth: 600, maxHeight: 10 };
  }
  if (size === 'medium') {
    style = { ...style, maxWidth: 600 };
  }

  if (size === 'small') {
    style = { ...style, maxWidth: 400 };
  }

  if (size === 'tiny') {
    style = {
      ...style,
      maxHeight: '150px',
      maxWidth: 350,
    };
  }

  return (
    <>
      {buttonText && <PrimaryButton text={buttonText} className={buttonClass} handleClick={handleOpen} />}

      <MuiModal open={open} onClose={handleClose}>
        <Box
          className={modalClass}
          sx={style}
          // disabled={ loading }
        >
          <Box className="basic__modal-box-header">
            {title && (
              <Typography className="basic__modal-box-title" variant="h2">
                {title}
              </Typography>
            )}

            {description && (
              <Typography className="basic__modal-box-description" variant="body1">
                {description}
              </Typography>
            )}
          </Box>

          <IconButton className="basic__modal-box-close" icon={<CloseRoundedIcon />} handleClick={handleClose} />

          {children}
          {confirmation && (
            <Box className="basic__modal-box-footer button-spacing">
              <PrimaryButton className="danger button-spacing" text="Confirm" handleClick={handleConfirm} />
              <PrimaryButton text="Cancel" className="button-spacing" handleClick={handleClose} />
            </Box>
          )}
        </Box>
      </MuiModal>
    </>
  );
};

export default Modal;
