import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

type Role = { key: string; value: string };

interface Props {
  role: string;
  options: Role[];
  handleChange: any;
}

const ChangeRole = ({ role, options, handleChange }: Props) => {
  return (
    <FormControl>
      <InputLabel id="demo-simple-select-label">Age</InputLabel>
      <Select labelId="demo-simple-select-label" id="select-role-${item.id}" value={role} label="Age" onChange={handleChange}>
        {options.map((item: Role) => {
          return <MenuItem value={item?.value}>{item?.key}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};

export default ChangeRole;
