import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../hooks/redux';
import LeftPanel from '../components/sections/left-panel';
import ContentPanel from '../components/sections/content-panel';
import getDatastreams from '../api/get-datastreams';
import DailyInfo from '../components/conversations/DailyInfo';
import getOrganizations from '../api/get-organizations';
import getModels from '../api/get-models';

const Customer = () => {
  const dispatch = useAppDispatch();
  const { org_id } = useParams();
  const organization = useAppSelector((state) => state.organization);
  const datastreams = useAppSelector((state) => state.datastreams);
  const models = useAppSelector((state) => state.models);
  const loading = useAppSelector((state) => state.loading);
  const datastream = useAppSelector((state) => state.datastream);
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    if (org_id && !organization) {
      const getOrganizationThunk = getOrganizations({ org_id: org_id }, dispatch, user);

      dispatch(getOrganizationThunk);
    }
  }, []);

  useEffect(() => {
    if (organization && !datastreams) {
      const getDatastreamsThunk = getDatastreams({ organization_id: organization.id }, dispatch, user);

      dispatch(getDatastreamsThunk);
    }
    if (datastream) {
      dispatch({ type: 'SET_DATASTREAM', payload: false });
    }

    if (organization && !models) {
      const getModelsThunk = getModels({ org_id: organization.id }, dispatch, user);

      dispatch(getModelsThunk);
    }
    if (org_id && !organization) {
      const getOrganizationThunk = getOrganizations({ org_id: org_id }, dispatch, user);
      dispatch(getOrganizationThunk);
    }
  }, [organization]);

  return (
    <div className="app conversation">
      <LeftPanel />

      <ContentPanel title={organization?.metadata?.company_name ?? '<No Name Found>'}>
        <DailyInfo orgid={organization?.id} />
      </ContentPanel>
    </div>
  );
};

export default Customer;
