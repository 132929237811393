import React, { useEffect } from 'react';
import PrimaryButton from '../button/PrimaryButton';
import Modal from '../modals/Modal';
import { Autocomplete, Divider, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import getPartners from '../../api/get-partners';
import addPartner from '../../api/add-partner';
import getOrganizations from '../../api/get-organizations';

interface FirstUser {
  email?: string;
  password?: string;
  first_name?: string;
  last_name?: string;
}

interface Error {
  name?: boolean;
  email?: boolean;
  password?: boolean;
  first_name?: boolean;
  last_name?: boolean;
}

interface Props {
  setAddNewPartner: (b: boolean) => void;
}

const AddPartner = ({ setAddNewPartner }: Props) => {
  const [error, setError] = React.useState<Error>({});

  const organizations = useAppSelector((state) => state.organizations);
  const user = useAppSelector((state) => state.user);
  const partners = useAppSelector((state) => state.partners);
  const partner = useAppSelector((state) => state.partner);
  const dispatch = useAppDispatch();
  const [firstUser, setFirstUser] = React.useState<FirstUser>({});
  const [filteredOrganizations, setFilteredOrganizations] = React.useState<any>([]);

  useEffect(() => {
    if (partner === false) {
      const getPartnersThunk = getPartners({}, dispatch, user);
      dispatch(getPartnersThunk);
    }
    if (!organizations) {
      const getOrganizationsThunk = getOrganizations({}, dispatch, user);
      dispatch(getOrganizationsThunk);
    }
    setAddNewPartner(true);
  }, []);

  useEffect(() => {
    if (organizations) {
      const newOrganizations = organizations.filter((item: any) => {
        if (item?.partner === undefined) {
          item.key = 'key-' + item?.id + '-' + item?.metadata?.company_name;
          return item;
        }
      });
      setFilteredOrganizations(newOrganizations);
    }
  }, [organizations]);

  const handleAddNew = () => {
    if (!partner?.name) {
      const newError = { ...error, name: true };
      setError(newError);
      return;
    }
    if (!firstUser?.email) {
      const newError = { ...error, email: true };
      setError(newError);
      return;
    }
    if (!firstUser.password) {
      const newError = { ...error, password: true };
      setError(newError);
      return;
    }
    if (!firstUser.first_name) {
      const newError = { ...error, password: true };
      setError(newError);
      return;
    }
    if (!firstUser.last_name) {
      const newError = { ...error, password: true };
      setError(newError);
      return;
    }

    const addPartnerThunk = addPartner({ partner: partner, first_user: firstUser }, dispatch, user);
    dispatch(addPartnerThunk);

    if (partners !== false && partners.some((p: any) => p.id === partner.id)) {
      const newPartners = partners.concat(partner);
      dispatch({ type: 'SET_PARTNERS', payload: newPartners });
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '20rem',
          justifyContent: 'space-between',
        }}
      >
        <Divider />
        <h3>{'First user'}</h3>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <TextField
            name="Email"
            label="Primary email"
            error={error.email}
            variant="outlined"
            value={firstUser?.email ?? ''}
            helperText="Enter email."
            autoComplete="new-name"
            key={'new-partner-email'}
            onChange={(event) => {
              const newError = { ...error, email: false };
              setError(newError);
              const newUser = { ...firstUser, email: event.target.value };
              setFirstUser(newUser);
            }}
          />
          <TextField
            name="Password"
            label="Password"
            type="password"
            error={error.password}
            variant="outlined"
            value={firstUser?.password ?? ''}
            helperText="Enter password."
            autoComplete="new-partner-password"
            key={'password-input'}
            onChange={(event) => {
              const newError = { ...error, password: false };
              setError(newError);
              const newUser = { ...firstUser, password: event.target.value };
              setFirstUser(newUser);
            }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <TextField
            name="first-name"
            label="First name"
            error={error.first_name}
            variant="outlined"
            value={firstUser?.first_name ?? ''}
            helperText="Please enter the surname of the user."
            autoComplete="new-name"
            key={'new-partner-email'}
            onChange={(event) => {
              const newError = { ...error, first_name: false };
              setError(newError);
              const newUser = { ...firstUser, first_name: event.target.value };
              setFirstUser(newUser);
            }}
          />
          <TextField
            name="last-name"
            label="Last name"
            error={error?.last_name}
            variant="outlined"
            value={firstUser?.last_name ?? ''}
            helperText="Enter password."
            autoComplete="new-partner-password"
            key={'new-partner-password'}
            onChange={(event) => {
              const newError = { ...error, last_name: false };
              setError(newError);
              const newUser = { ...firstUser, last_name: event.target.value };
              setFirstUser(newUser);
            }}
          />
        </div>

        <Divider />
        <h3>{'Partner settings'}</h3>
        <TextField
          name="partner-name"
          label="Partner Name"
          error={error.name}
          variant="outlined"
          value={partner?.name ?? ''}
          helperText="Enter partner name here."
          autoComplete="new-name"
          key={'new-partner-name'}
          onChange={(event) => {
            const newError = { ...error, name: false };
            setError(newError);
            const newPartner = { ...partner, name: event.target.value };
            dispatch({ type: 'SET_PARTNER', payload: newPartner });
          }}
        />
        <Autocomplete
          multiple
          limitTags={2}
          fullWidth
          id="multiple-attached-organizations"
          options={filteredOrganizations ?? []}
          getOptionLabel={(option: any) => (option?.metadata?.company_name ? option?.metadata?.company_name + ': ' + option?.id : '')}
          // key={'new-partner-organizations'}
          renderInput={(params) => <TextField {...params} label="Organization" />}
          sx={{ width: '500px' }}
          onChange={(event, value) => {
            const attached_org_ids = value.map((org: any) => org.id);
            const newPartner = { ...partner, organizations: attached_org_ids };
            dispatch({ type: 'SET_PARTNER', payload: newPartner });
          }}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <PrimaryButton text={'Add partner'} handleClick={handleAddNew} />
        </div>
      </div>
    </>
  );
};

export default AddPartner;
