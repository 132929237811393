interface Props {
  type: 'SET_PARTNER' | 'GET_PARTNER';
  payload: any;
}

const partnerReducer = (state = false, action: Props) => {
  switch (action.type) {
    case 'SET_PARTNER':
      return action.payload;
    case 'GET_PARTNER':
      return state;
    default:
      return state;
  }
};

export default partnerReducer;
