import { Autocomplete, TextField } from '@mui/material';
import { use } from 'i18next';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import getOrganizations from '../../api/get-organizations';
import PrimaryButton from '../button/PrimaryButton';
import PartnerOrganization from '../partner/tabs/PartnerOrganization';
import updatePartner from '../../api/update-partner';

const OrganizationAutocomplete = () => {
  const [filteredOrganizations, setFilteredOrganizations] = React.useState<any>([]);
  const [partnerOrganizations, setPartnerOrganizations] = React.useState<any>([]);
  const partner = useAppSelector((state) => state.partner);
  const dispatch = useAppDispatch();
  const organizations = useAppSelector((state) => state.organizations);
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    if (!organizations) {
      const getOrganizationsThunk = getOrganizations({}, dispatch, user);
      dispatch(getOrganizationsThunk);
    }

    if (organizations) {
      const newOrganizations = organizations.filter((item: any) => {
        if (item?.partner === undefined && item?.id !== undefined && item?.type !== 'partner') {
          item.key = 'key-' + item?.id + '-' + item?.metadata?.company_name;
          return item;
        }
      });
      setFilteredOrganizations(newOrganizations);
    }
  }, [organizations]);

  const handleClick = () => {
    let partnerCopy = partner;
    partnerOrganizations.map((organization: any) => {
      partnerCopy = { ...partnerCopy, organizations: [...partnerCopy.organizations, organization?.id] };
    });
    dispatch({ type: 'SET_PARTNER', payload: partnerCopy });
    const updatePartnerThunk = updatePartner({ partner: partnerCopy }, dispatch, user);
    dispatch(updatePartnerThunk);
  };

  return (
    <>
      <Autocomplete
        multiple
        limitTags={2}
        fullWidth
        id="multiple-attached-organizations"
        options={filteredOrganizations ?? []}
        getOptionLabel={(option: any) => (option?.metadata?.company_name ? option?.metadata?.company_name + ': ' + option?.id : '')}
        // key={'new-partner-organizations'}
        renderInput={(params) => <TextField {...params} label="Organization" />}
        sx={{ width: '500px' }}
        onChange={(event: any, value) => {
          setPartnerOrganizations(value);
        }}
      />
      <PrimaryButton text={'Save'} handleClick={handleClick} />
    </>
  );
};

export default OrganizationAutocomplete;
