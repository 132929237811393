import axios from 'axios';
import { updateDocument } from '../hooks/firestore';
import { ACCESS_TOKEN, API_ENDPOINTS } from '../utils/constants';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { authDestroy } from '../utils/authentication';

interface ModelReducerProps {
  type: 'SET_ORGANIZATION' | 'GET_ORGANIZATION' | 'UPDATE_ORGANIZATION';
  payload: any;
}

const modelReducer = (state = false, action: ModelReducerProps) => {
  switch (action.type) {
    case 'SET_ORGANIZATION':
      return action.payload;
    case 'GET_ORGANIZATION':
      return state;
    case 'UPDATE_ORGANIZATION':
      // if (action.payload && action.payload.id && action.payload.user) {
      if (action.payload && action.payload.user && action.payload.organization) {
        updateOrganization(action.payload);
      }
      return state;
    default:
      return state;
  }
};

export default modelReducer;

const updateOrganization = (payload: any) => {
  const fetch = async () => {
    const params = {
      params: {
        org_id: payload.organization.id,
        organization: payload.organization,
      },
      uid: payload.user.id,
      org_id: payload.user.org_id,
    };
    await axios.patch(`${API_ENDPOINTS.KARLA_FLASK_API}/karla/operations/organizations/set`, params, {
      headers: {
        Authorization: `Bearer ${ACCESS_TOKEN}`,
      },
    });
  };
  try {
    fetch();
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      // Handling 401 error
      authDestroy();
    }
  }
};
