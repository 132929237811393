interface Props {
  type: 'SET_PARTNERS' | 'GET_PARTNERS';
  payload: any;
}

const partnersReducer = (state = false, action: Props) => {
  switch (action.type) {
    case 'SET_PARTNERS':
      return action.payload;
    case 'GET_PARTNERS':
      return state;
    default:
      return state;
  }
};

export default partnersReducer;
