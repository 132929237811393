import { useEffect, useState } from 'react';
import { useAppDispatch } from '../hooks/redux';
import { sendPasswordResetEmail } from 'firebase/auth';

import { FIREBASE_AUTH } from '../utils/firebase';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import PrimaryButton from '../components/button/PrimaryButton';
import { Card, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
// import Card from '../components/cards/Card';

const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const default_email = queryParams.get('e');

  const [userCredentials, setUserCredentials] = useState({
    email: '',
    password: '',
  });
  useEffect(() => {
    setUserCredentials({ email: default_email ? default_email?.toString() : '', password: '' });
  }, [default_email]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // some middleware to check if the email and password are valid
    if (!userCredentials?.email.includes('@') && !userCredentials?.email.includes('.')) {
      dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'Error: Invalid email' } });
      return;
    }

    sendPasswordResetEmail(FIREBASE_AUTH, userCredentials?.email)
      .then(() => {
        dispatch({
          type: 'DISPLAY_SUCCESS',
          payload: { reason: 'Reset password email sent' },
        });
        navigate('/login');
      })
      .catch((error) => {
        dispatch({
          type: 'DISPLAY_ERROR',
          payload: { reason: 'An error occurred. Please try again' },
        });
      });
  };

  return (
    <Box className="auth__container">
      <Card sx={{ p: 5 }}>
        <Typography className="auth__container-title" variant="h1">
          {'Reset your password'}
        </Typography>

        <Typography className="auth__container-subtitle">Enter your email below and we'll send you instructions for resetting your password.</Typography>

        <form className="auth__container-form form" onSubmit={handleSubmit}>
          <FormControl className="form__fields">
            <TextField
              label="E-mail"
              value={userCredentials.email}
              variant="outlined"
              autoComplete="new-email"
              key={'email-input'}
              onChange={(event) => {
                const updatedCredentials = { ...userCredentials, email: event?.target?.value };
                setUserCredentials(updatedCredentials);
              }}
            />
            <PrimaryButton text="Reset password" handleType="submit" />
          </FormControl>
        </form>
      </Card>
    </Box>
  );
};

export default ResetPassword;
