import React from 'react';
import PrimaryButton from '../button/PrimaryButton';
import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import axios from 'axios';
import { ACCESS_TOKEN, API_ENDPOINTS } from '../../utils/constants';
import Modal from '../modals/Modal';
import './DatastreamOptions.css';
import { authDestroy } from '../../utils/authentication';

const DatastreamOptions = () => {
  const dispatch = useAppDispatch();
  const datastream = useAppSelector((state) => state.datastream);
  const user = useAppSelector((state) => state.user);

  const sendDatastreamUpdate = (dispatch: any, datastream: any, option: string) => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    let message = '';
    if (option !== 'reset' && option !== 'delete') {
      return Promise.reject(new Error('Invalid option'));
    }
    if (option === 'delete') {
      message = 'Datastream data has been deleted';
    } else {
      message = 'Datastream state has been reset';
    }
    const params = {
      params: {
        org: datastream.org_id,
        datastream: datastream.id,
        option: option,
      },
      uid: user?.id,
      org_id: user?.org_id,
    };
    axios
      .post(`${API_ENDPOINTS.KARLA_FLASK_API}/karla/streams/update`, params, {
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      })
      .then((response) => {
        if (response.data && response.data.type === 'success') {
          dispatch({ type: 'DISPLAY_SUCCESS', payload: { reason: message } });
        } else {
          throw new Error('Failed to update datastream');
        }
      })
      .catch((error: any) => {
        if (error.response && error.response.status === 401) {
          // Handling 401 error
          authDestroy();
        } else {
          // Handling other errors
          dispatch({ type: 'DISPLAY_ERROR', payload: { reason: error.message } });
        }
      })
      .finally(() => {
        dispatch({ type: 'SET_IS_LOADING', payload: false });
      });
  };
  return (
    <div>
      <h1>Options</h1>
      <div className="modal">
        <Modal size={'tiny'} buttonText={`Delete data from ${datastream?.name}`} children={'This action is permanent and irreversible'} confirmation={true} handleClick={() => sendDatastreamUpdate(dispatch, datastream, 'delete')} />
        {/* <PrimaryButton text={`Delete data from ${datastream?.name}`} handleClick={() => sendDatastreamUpdate(dispatch, datastream, 'delete')} /> */}
      </div>
      <div className="modal">
        <Modal size={'tiny'} buttonText={`Reset State in ${datastream?.name}`} children={'This action is permanent and irreversible'} confirmation={true} handleClick={() => sendDatastreamUpdate(dispatch, datastream, 'reset')} />
        {/* <PrimaryButton text={`Reset state in ${datastream?.name}`} handleClick={() => sendDatastreamUpdate(dispatch, datastream, 'reset')} /> */}
      </div>{' '}
    </div>
  );
};

export default DatastreamOptions;
