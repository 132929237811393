import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../hooks/redux';

import collect from '../../api/collect';

import PrimaryButton from '../button/PrimaryButton';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const CollectData = () => {
  const dispatch = useAppDispatch();
  const socket = useAppSelector((state) => state.socket);
  const display_message = useAppSelector((state) => state.display_message);
  const organization = useAppSelector((state) => state.organization);
  const datastreams = useAppSelector((state) => state.datastreams);
  const [stream, setStream] = useState<any>();
  const user = useAppSelector((state) => state.user);

  const handleChange = (event: any) => {
    setStream(event.target.value);
  };

  useEffect(() => {
    if (!socket?.collect) {
      dispatch({ type: 'CONNECT_SOCKET', which: 'CONNECT_COLLECT' });
    }

    if (socket?.collect) {
      socket?.collect.on('connect', function () {
        socket?.collect.emit('join_room', socket.id);
      });

      // Listen for data messages from the Flask server
      socket.collect.on('collect_progress_status', (message: any) => {
        // Display warning shows progress of collecting
        dispatch({ type: 'DISPLAY_WARNING', payload: { reason: message } });
      });

      socket?.collect.io.on('reconnect', (attempt: any) => {
        console.log('reconnected', attempt);
      });

      socket?.collect.on('connect_error', (error: any) => {
        console.log('connect_error', error);
      });

      socket?.collect.on('disconnect', (reason: any) => {
        if (reason === 'io server disconnect') {
          // the disconnection was initiated by the server, you need to reconnect manually
          socket?.collect.connect();
        }
      });
    }
  }, [socket?.collect, display_message]);

  const collectFreshData = () => {
    if (!datastreams && !socket.collect) {
      return;
    }

    // console.log('Starts collection data process...', socket.collect?.id);

    let collectStreams = datastreams;

    if (stream) {
      const chosenStream = datastreams.filter((s: any) => s?.id === stream);
      collectStreams = chosenStream;
      // console.log('USE THIS?', chosenStream);
    }

    const collectThunk = collect(
      {
        org_id: organization?.id,
        room: socket.collect?.id,
        limit: -1,
      },
      collectStreams,
      dispatch,
      user,
    );

    dispatch(collectThunk);
  };

  if (!organization) {
    return <></>;
  }

  if (!datastreams) {
    return <></>;
  }

  return (
    <>
      <Box className="collect-data">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Datastream</InputLabel>
          <Select labelId="demo-simple-select-label" id="demo-simple-select" value={stream?.id} label="Datastream" onChange={handleChange}>
            {datastreams &&
              datastreams.map((stream: any) => {
                return (
                  <MenuItem key={stream?.id} value={stream?.id}>
                    {stream?.name} ({stream?.id})
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <PrimaryButton text="Collect fresh data" handleClick={collectFreshData} />
      </Box>
    </>
  );
};

export default CollectData;
