import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../hooks/redux';
import { authUser } from '../utils/authentication';
import { ACCESS_TOKEN } from '../utils/constants';
import jwtDecode from 'jwt-decode';

interface Props {
  redirectPath?: string;
  children?: React.ReactNode | any;
}

const SuperProtectedRoute = ({ redirectPath = '/login', children }: Props) => {
  if (!authUser()) {
    return <Navigate to={redirectPath} />;
  }

  if (ACCESS_TOKEN && jwtDecode<any>(ACCESS_TOKEN).role !== 'super_admin') {
    return <Navigate to={redirectPath} />;
  }
  return children ? children : <Outlet />;
};
export default SuperProtectedRoute;
