import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FolderIcon from '@mui/icons-material/Folder';
import { Avatar, ListItemAvatar } from '@mui/material';

const OrganizationListItem = ({ organization }: any) => {
  return (
    <Link className={'operations-list-item'} to={`/customers/${organization?.id}`} state={{ customer: organization }}>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <FolderIcon />
          </Avatar>
        </ListItemAvatar>
        {/* <ListItemText primary={organization?.metadata?.company_name ?? "<No Name Found>"} secondary={organization?.metadata?.country} /> */}
        <ListItemText secondary={organization?.metadata?.company_name ?? 'N/A'} sx={{ width: '33%' }} />
        <ListItemText secondary={organization?.metadata?.country ?? 'N/A'} sx={{ width: '33%' }} />
        <ListItemText secondary={organization?.id ?? 'N/A'} sx={{ width: '33%' }} />
      </ListItem>
    </Link>
  );
};

export default OrganizationListItem;
