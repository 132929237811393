import { Box, Card, FormControl, Link, TextField, Typography } from '@mui/material';
import React from 'react';
import PrimaryButton from '../button/PrimaryButton';
import { ReactComponent as KarlaKIcon } from '../../assets/img/karla-k-icon.svg';
import { APP_ROOT } from '../../utils/constants';

interface Props {
  oneTimeCode: string;
  setOneTimeCode: (oneTimeCode: string) => void;
  email: string;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

const LoginOTC = ({ oneTimeCode, setOneTimeCode, email, handleSubmit }: Props) => {
  const ResetPassword: React.FC = () => {
    return (
      <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <Typography sx={{ color: '#333', fontSize: '14px' }}>
          <Link
            href={`${APP_ROOT}/reset-password?e=${email}`}
            underline="hover"
            sx={{
              fontWeight: 'bold',
              fontSize: '14px',
              color: '#1976d2',
            }}
          >
            {'Reset password'}
          </Link>
        </Typography>
      </div>
    );
  };
  return (
    <Card sx={{ p: 2.5 }}>
      <Box className="auth__container-logo">
        <KarlaKIcon />
      </Box>

      <Typography className="auth__container-title" variant="h1">
        {'Welcome back!'}
      </Typography>

      <form className="auth__container-form form" onSubmit={handleSubmit}>
        <FormControl className="form__fields">
          <TextField label="E-mail" value={email} variant="outlined" disabled key={'email-display'} />
          <TextField
            label="One time code"
            type="OTP"
            variant="outlined"
            key={'OTP-input'}
            value={oneTimeCode}
            onChange={(event) => {
              setOneTimeCode(event?.target?.value);
            }}
          />

          <PrimaryButton text="Login" handleType="submit" />
          <ResetPassword />
        </FormControl>
      </form>
    </Card>
  );
};

export default LoginOTC;
