import { useNavigate, useParams, NavLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';

import { ReactComponent as KarlaKIcon } from '../../../assets/img/karla-k-icon.svg';
import { Box } from '@mui/material';

const Header = () => {
  const user = useAppSelector((state) => state.user);

  return (
    <div className="left-panel__top">
      <NavLink to="/">
        <Box className="auth__container-logo">
          <KarlaKIcon />
        </Box>
      </NavLink>

      {user && (
        <>
          <div className="left-panel__top-plans">
            <div className="left-panel__top-plans-name">KarlaChat Organization</div>
            <div className="left-panel__top-plans-type">Enterprise Plan</div>
          </div>
        </>
      )}
    </div>
  );
};

export default Header;
