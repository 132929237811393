import { Skeleton } from '@mui/material';
import React from 'react';

interface Props {
  user_name: string | null | undefined;
  email?: string;
}

const DisplayName = ({ user_name, email }: Props) => {
  if (user_name === undefined) {
    return (
      <div className="left-panel__user-names">
        <Skeleton variant="rectangular" width={`100%`} height={30} />
      </div>
    );
  }
  return (
    <div className="left-panel__user-names">
      <div>{user_name && user_name}</div>
      <div style={{ fontSize: 12, color: 'grey' }}>{email}</div>
    </div>
  );
};

export default DisplayName;
