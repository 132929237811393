import MenuItem from '@mui/material/MenuItem';

import trainModel from '../../api/train-model';
import PrimaryButton from '../button/PrimaryButton';

import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { FormControl, InputLabel, Select } from '@mui/material';
import { Dictionary } from '@reduxjs/toolkit';
import { useState } from 'react';
import { get } from 'http';
import SelectModel from './SelectModel';

const TrainModel = () => {
  const dispatch = useAppDispatch();
  const organization = useAppSelector((state) => state.organization);
  const model = useAppSelector((state) => state.model);
  const user = useAppSelector((state) => state.user);

  const handleTrainModel = () => {
    const trainModelThunk = trainModel(
      {
        model: model,
        uid: organization?.id,
        manually_triggered_embedding: true,
      },
      dispatch,
      user,
    );

    dispatch(trainModelThunk);
  };
  return (
    <>
      <h1 style={{ display: 'flex', justifyContent: 'space-around' }}>
        Model: {model.name} ({model.id})
      </h1>
      <PrimaryButton handleClick={handleTrainModel} text="Train model" />
      <SelectModel />
    </>
  );
};

export default TrainModel;
