import { MouseEvent, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { authDestroy } from '../../../utils/authentication';

import { useTranslation } from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useAppDispatch } from '../../../hooks/redux';

const ToggleSettings = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch({ type: 'SET_USER', payload: false });
    authDestroy();
  };

  const options = [t('Change avatar'), t('Privacy')];

  return (
    <>
      <IconButton aria-label="more" id="long-button" aria-controls={open ? 'long-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>

      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        className="left-panel__user-settings-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '175px',
            boxShadow: 'var(--card-shadow)',
            margin: '-40px 0 0 30px',
            border: '1px solid #f6f6f7',
          },
        }}
      >
        {/* {options.map((option) => (
          <NavLink to="/projects" key={option}>
            <MenuItem onClick={handleClose}>{option}</MenuItem>
          </NavLink>
        ))} */}
        <NavLink to="/login">
          <MenuItem onClick={handleLogout}>{t('Log out')}</MenuItem>
        </NavLink>
      </Menu>
    </>
  );
};

export default ToggleSettings;
