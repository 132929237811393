import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../hooks/redux';

import LeftPanel from '../components/sections/left-panel';
import ContentPanel from '../components/sections/content-panel';

import getModel from '../api/get-model';
import TrainModel from '../components/models/TrainModel';
import LoadingBackdrop from '../components/loading/LoadingBackdrop';
import getOrganizations from '../api/get-organizations';

const Model = () => {
  const dispatch = useAppDispatch();
  const { org_id } = useParams();
  const { model_id } = useParams();
  const organization = useAppSelector((state) => state.organization);
  const organizations = useAppSelector((state) => state.organizations);
  const model = useAppSelector((state) => state.model);
  const loading = useAppSelector((state) => state.loading);
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    if (model_id && org_id) {
      const getModelThunk = getModel({ model: model_id, organization_id: org_id }, dispatch, user);

      dispatch(getModelThunk);
    }
    if (!organizations) {
      const getOrganizationsThunk = getOrganizations({ org_id: org_id }, dispatch, user);
      dispatch(getOrganizationsThunk);
    }
  }, [model_id, org_id]);

  useEffect(() => {
    if (!organization) {
      if (organizations) {
        dispatch({ type: 'SET_ORGANIZATION', payload: organizations.find((org: any) => org.id === org_id) });
      }
    }
  }, [organizations]);
  return (
    <div className="app conversation">
      <LeftPanel />
      <ContentPanel title={organization?.metadata?.company_name}>
        <LoadingBackdrop open={loading} />
        <TrainModel />
      </ContentPanel>
    </div>
  );
};

export default Model;
