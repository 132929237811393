import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FolderIcon from '@mui/icons-material/Folder';
import { Avatar, ListItemAvatar } from '@mui/material';

interface Props {
  partner: any;
}

const PartnerListItem = ({ partner }: Props) => {
  return (
    <Link className={'operations-list-item'} to={`/partners/${partner?.id}`} state={{ customer: partner }}>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <FolderIcon />
          </Avatar>
        </ListItemAvatar>
        {/* <ListItemText primary={organization?.metadata?.company_name ?? "<No Name Found>"} secondary={organization?.metadata?.country} /> */}
        <ListItemText secondary={partner?.metadata?.company_name ?? 'N/A'} sx={{ width: '33%' }} />
        <ListItemText secondary={partner?.created.split(' ')[0] ?? 'N/A'} sx={{ width: '33%' }} />
        <ListItemText secondary={partner?.id ?? 'N/A'} sx={{ width: '33%' }} />
      </ListItem>
    </Link>
  );
};

export default PartnerListItem;
