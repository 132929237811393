import React, { useEffect } from 'react';
import ContentPanel from '../components/sections/content-panel';
import LeftPanel from '../components/sections/left-panel';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import getPartners from '../api/get-partners';
import { useParams } from 'react-router-dom';
import { Tab, Tabs, Typography } from '@mui/material';
import TabPanel from '../components/sections/tab-panel/TabPanel';
import PartnerUsers from '../components/partner/tabs/PartnerUsers';
import PartnerOrganization from '../components/partner/tabs/PartnerOrganization';
import PartnerGeneral from '../components/partner/tabs/PartnerGeneral';
import getPartnerUsers from '../api/get-partner-users';
import getPartnerOrganizations from '../api/get-partner-organizations';
import Modal from '../components/modals/Modal';
import OrganizationAutocomplete from '../components/organizations/OrganizationAutocomplete';

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Partner = () => {
  const partners = useAppSelector((state) => state.partners);
  const user = useAppSelector((state) => state.user);
  const partner = useAppSelector((state) => state.partner);
  const { partner_id } = useParams();
  const [value, setValue] = React.useState(0);
  const organization_users = useAppSelector((state) => state.organization_users);
  const [partnerOrganizations, setPartnerOrganizations] = React.useState([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (partners === false) {
      const getPartnersThunk = getPartners({}, dispatch, user);
      dispatch(getPartnersThunk);
    }
    if (partners !== false && partner === false) {
      const partner = partners.find((partner: any) => partner.id === partner_id);
      dispatch({ type: 'SET_PARTNER', payload: partner });
    }
    if (partner !== false) {
      const getPartnerUsersThunk = getPartnerUsers({ partner_id: partner_id }, dispatch, user);
      dispatch(getPartnerUsersThunk);
    }
    if (partner !== false) {
      const getPartnerOrganizationsThunk = getPartnerOrganizations({ partner_id: partner_id }, dispatch, user, setPartnerOrganizations);
      dispatch(getPartnerOrganizationsThunk);
    }
  }, [partners, partner]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div className="app customers">
      <LeftPanel />
      <ContentPanel title={'Partner: ' + partner?.metadata?.company_name + ' (' + partner?.id + ')'}>
        <Tabs value={value} onChange={handleChange} aria-label="info-tabs">
          <Tab label="General" {...a11yProps(0)} />
          <Tab label="Users" {...a11yProps(1)} />
          <Tab label="Organizations" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Typography variant="h6" gutterBottom>
            <PartnerGeneral />
          </Typography>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Typography variant="h6" gutterBottom>
            <PartnerUsers partnerUsers={organization_users} />
          </Typography>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Typography variant="h6" gutterBottom>
            <Modal buttonText={'Add organization'} title={'Add organization to partner'} description={'Here you can find all organizations registered, and add them to the partner'} children={<OrganizationAutocomplete />} />
            <PartnerOrganization partnerOrganizations={partnerOrganizations} />
          </Typography>
        </TabPanel>
      </ContentPanel>
    </div>
  );
};

export default Partner;
