import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../hooks/redux';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

import login from '../api/login';
import { API_ENDPOINTS } from '../utils/constants';
import axios from 'axios';
import LoginForm from '../components/login/LoginForm';
import LoginOTC from '../components/login/LoginOTC';
import { authDestroy } from '../utils/authentication';

const LogIn = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userCredentials, setUserCredentials] = useState({
    email: '',
    password: '',
  });
  const isValidEmail = (email: string): boolean => /\S+@\S+\.\S+/.test(email);
  const [oneTimeCode, setOneTimeCode] = useState('');
  const [oneTimeCodeSent, setOneTimeCodeSent] = useState(false);

  const handleUserLoginErrors = (errorCode: string) => {
    const errorMessages: Record<string, string> = {
      'auth/user-not-found': 'Either email or password is incorrect',
      'auth/invalid-email': 'Invalid email address.',
      'auth/wrong-password': 'Either email or password is incorrect',
      'auth/too-many-requests': 'Too many login attempts. Try again later.',
    };

    const errorMessage = errorMessages[errorCode] || 'An unknown error occurred.';
    dispatch({ type: 'DISPLAY_ERROR', payload: { reason: errorMessage } });
  };

  const sendVerificationCode = async () => {
    try {
      const response = await axios.post(`${API_ENDPOINTS.KARLA_FLASK_API}/user/send-otc`, {
        user_email: userCredentials?.email,
      });

      if (response.data.type === 'success') {
        setOneTimeCodeSent(true);
      } else {
        dispatch({ type: 'DISPLAY_ERROR', payload: { reason: response.data.reason } });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
        if (error.response && error.response.status === 429) {
          dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'Too many attempts, please try again later.' } });
          return;
        }
        if (error.response && error.response.status === 400) {
          dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'Failed to send one-time code.' } });
          return;
        }
        if (error.response && error.response.status === 401) {
          // Handling 401 error
          authDestroy();
        }
      } else {
        dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'An unexpected error occurred.' } });
        return;
      }
      return false;
    }
  };

  const checkLogin = async () => {
    try {
      const response = await axios.post(`${API_ENDPOINTS.KARLA_FLASK_API}/user/signin`, {
        user_email: userCredentials?.email,
        password: userCredentials?.password,
      });
      if (response.data.type === 'success') {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
        if (error.response && error.response.status === 429) {
          dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'Too many attempts, please try again later.' } });
          return;
        }
        if (error.response && error.response.status === 400) {
          dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'Invalid credentials' } });
          return;
        }
        if (error.response && error.response.status === 401) {
          // Handling 401 error
          authDestroy();
        }
      } else {
        dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'An unexpected error occurred.' } });
        return;
      }
      return false;
    }
  };
  const checkOneTimeCode = async () => {
    try {
      const response = await axios.post(`${API_ENDPOINTS.KARLA_FLASK_API}/user/check-otc`, {
        one_time_code: oneTimeCode,
        user_email: userCredentials?.email,
      });

      if (response.data.type === 'success') {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
        if (error.response && error.response.status === 429) {
          dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'Too many attempts, please try again later.' } });
          return;
        }
        if (error.response && error.response.status === 400) {
          dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'Invalid one-time code.' } });
          return;
        }
        if (error.response && error.response.status === 401) {
          // Handling 401 error
          authDestroy();
        }
      } else {
        dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'An unexpected error occurred.' } });
        return;
      }
      return false;
    }
  };

  const handleVerificationCodeCheck = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isValidEmail(userCredentials.email)) {
      dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'Invalid email address.' } });
      return;
    }

    if (!oneTimeCode) {
      dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'One-time code cannot be empty.' } });
      return;
    }

    try {
      const verified_otc: any = await checkOneTimeCode();
      if (verified_otc) {
        const params = {
          email: userCredentials.email,
          password: userCredentials.password,
        };

        const loginThunk = login(params, dispatch);
        dispatch(loginThunk);
      }
    } catch (error) {
      dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'An error occurred during verification.' } });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const handleSendVerificationCode = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!isValidEmail(userCredentials.email)) {
        dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'Invalid email address.' } });
        return;
      }

      if (!userCredentials.password) {
        dispatch({ type: 'DISPLAY_ERROR', payload: { reason: 'Password cannot be empty.' } });
        return;
      }

      try {
        const canLogin = await checkLogin();
        if (canLogin === true) {
          await sendVerificationCode();
        }
      } catch (error: any) {
        handleUserLoginErrors(error.code);
      }
    };
    handleSendVerificationCode(event);
  };

  return (
    <Box className="auth__container">
      {!oneTimeCodeSent && <LoginForm userCredentials={userCredentials} setUserCredentials={setUserCredentials} handleSubmit={handleSubmit} />}
      {oneTimeCodeSent && <LoginOTC oneTimeCode={oneTimeCode} setOneTimeCode={setOneTimeCode} email={userCredentials.email} handleSubmit={handleVerificationCodeCheck} />}
    </Box>
  );
};

export default LogIn;
