import moment from 'moment';
import { doc, setDoc, updateDoc, addDoc, getDoc, getCountFromServer, getDocs, onSnapshot, query, startAt, endAt, collection, where, collectionGroup, Timestamp, orderBy, startAfter, limit } from 'firebase/firestore';
import { FIRESTORE_DB } from '../utils/firebase';
import { useEffect } from 'react';

// Add a new document with a generated id.
export const updateDocument = async (collection_name: string, doc_id: string, data: any) => {
  try {
    const ref = doc(FIRESTORE_DB, collection_name, doc_id);
    const docSnap = await updateDoc(ref, data);
    return docSnap;
  } catch (e) {
    console.error('Error updating document: ', e);

    return false;
  }
};

// Add a new document with a generated id.
export const getDocument = async (collection_name: string, doc_id: string) => {
  try {
    const ref = doc(FIRESTORE_DB, collection_name, doc_id);
    const docSnap = await getDoc(ref);

    if (docSnap.exists() && docSnap.id) {
      return { ...docSnap.data(), id: docSnap.id };
    } else {
      console.log('No such document!');
    }
  } catch (e) {
    console.error('Error adding document: ', e);

    return false;
  }
};

// Add a new document with a custom or generated id.
export const addNewDocument = async (collection_name: string, doc_id = '', data: object) => {
  try {
    let ref;
    if (doc_id !== '') {
      ref = await setDoc(doc(FIRESTORE_DB, collection_name, doc_id), data);
    } else {
      ref = await addDoc(collection(FIRESTORE_DB, collection_name), data);
    }

    return ref;
  } catch (e) {
    console.error('Error adding document: ', e);

    return false;
  }
};

// Gets a list of documents as pagination
export const getDocumentPaginationList = async (collection_name: string, field_key: string, field_value: string, order_key: string, order_val: any, page: any, rows_per_page: number, end_limit: number) => {
  // Query the first page of docs
  const first = query(collection(FIRESTORE_DB, collection_name), where(field_key, '==', field_value), orderBy(order_key, order_val), limit(end_limit));
  let querySnapshot = await getDocs(first);

  // Count total documents
  const countTotalQuery = query(collection(FIRESTORE_DB, collection_name), where(field_key, '==', field_value), orderBy(order_key, order_val));
  const queryCountTotalSnapshot = await getCountFromServer(countTotalQuery);
  const countTotal = queryCountTotalSnapshot.data().count;

  // Count todays documents
  const today = new Date().toISOString().slice(0, 10);
  console.log('today', today);

  const countTodayQuery = query(collection(FIRESTORE_DB, collection_name), where(field_key, '==', field_value), where('updated', '>=', today), orderBy(order_key, order_val));
  const queryCountTodaySnapshot = await getCountFromServer(countTodayQuery);
  const countToday = queryCountTodaySnapshot.data().count;

  // Count this week documents
  const thisWeek = moment().startOf('week').format().toString();
  const countThisWeekQuery = query(collection(FIRESTORE_DB, collection_name), where(field_key, '==', field_value), where('updated', '>=', thisWeek), orderBy(order_key, order_val));
  const queryCountThisWeekSnapshot = await getCountFromServer(countThisWeekQuery);
  const countThisWeek = queryCountThisWeekSnapshot.data().count;

  if (page !== 0) {
    // Get the last visible document
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    console.log('last', lastVisible.data()?.updated);

    const q = query(collection(FIRESTORE_DB, collection_name), where(field_key, '==', field_value), orderBy(order_key, order_val), startAt(lastVisible), limit(rows_per_page));

    querySnapshot = await getDocs(q);
  }

  const list = querySnapshot.docs.map((doc) => {
    // doc.data() is never undefined for query doc snapshots
    return { ...doc.data(), id: doc.id };
  });

  return { list: list, total: countTotal, today: countToday, this_week: countThisWeek };
};

interface DocumentList {
  [key: string]: any;
  id: string;
}

// Gets a collection
export const getCollection = async (collection_name: string, order_key?: any, order_val?: any): Promise<DocumentList[]> => {
  let q;

  if (order_key && order_val) {
    q = query(collection(FIRESTORE_DB, collection_name), orderBy(order_key, order_val));
  } else {
    q = query(collection(FIRESTORE_DB, collection_name));
  }

  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    // doc.data() is never undefined for query doc snapshots
    return { ...doc.data(), id: doc.id };
  });

  return list;
};

// Gets a list of documents
export const getDocumentList = async (collection_name: string, field_key: string, field_value: string, order_key?: any, order_val?: any): Promise<DocumentList[]> => {
  let q;

  if (order_key && order_val) {
    q = query(collection(FIRESTORE_DB, collection_name), where(field_key, '==', field_value), orderBy(order_key, order_val));
  } else {
    q = query(collection(FIRESTORE_DB, collection_name), where(field_key, '==', field_value));
  }

  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    // doc.data() is never undefined for query doc snapshots
    return { ...doc.data(), id: doc.id };
  });

  return list;
};

// Add a new document with a generated id.
export const snapshotDocumentPaginationList = (collection_name: string, field_key: string, field_value: string, page: any, rows_per_page: number, end_limit: number, currentState: any, setNewState: any, firstSnapshot = true) => {
  /* Listening on the sub collection "queries" for changes. */
  useEffect(() => {
    // Find all that matches
    const q = query(collection(FIRESTORE_DB, collection_name), where(field_key, '==', field_value), orderBy('updated', 'desc'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      // Return first snapshot as that contains every document available. We only need the changes.
      if (firstSnapshot) {
        firstSnapshot = false;
        return;
      }

      snapshot.docChanges().forEach((change) => {
        // Whenever a new query is added to the sub collection
        if (change.type === 'added') {
          const data = change.doc.data();

          console.log('current state before', currentState);
          currentState.list.concat([data]);

          console.log('current state after', currentState);

          console.log('new data', data);

          // Re-count total documents
          const updateCounters = async () => {
            const countTotalQuery = query(collection(FIRESTORE_DB, collection_name), where(field_key, '==', field_value));
            const queryCountTotalSnapshot = await getCountFromServer(countTotalQuery);
            const countTotal = queryCountTotalSnapshot.data().count;
            currentState.total = countTotal;

            // Re-count todays documents
            const today = new Date().toISOString().slice(0, 10);
            const countTodayQuery = query(collection(FIRESTORE_DB, collection_name), where(field_key, '==', field_value), where('updated', '>=', today));
            const queryCountTodaySnapshot = await getCountFromServer(countTodayQuery);
            const countToday = queryCountTodaySnapshot.data().count;
            currentState.today = countToday;

            setNewState(currentState);
          };

          updateCounters();
        }

        // Whenever the recently added in the sub collection is modified / changed
        if (change.type === 'modified') {
          const doc = change.doc.data();

          const updatedList = currentState.list.map((value: any) => {
            return value?.id === doc?.id ? doc : value;
          });

          currentState.list = updatedList;

          console.log('updated current state', currentState);

          setNewState(currentState);
        }
      });
    });

    return () => {
      unsubscribe();
    };
  }, [currentState]);
};

// Add a new document with a generated id.
export const snapshotDocumentList = (collection_name: string, field_key: string, field_value: string, currentState: any, setNewState: any, firstSnapshot = true) => {
  /* Listening on the sub collection "queries" for changes. */
  useEffect(() => {
    // Find all that matches
    const q = query(collection(FIRESTORE_DB, collection_name), where(field_key, '==', field_value), orderBy('updated', 'desc'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      // Return first snapshot as that contains every document available. We only need the changes.
      if (firstSnapshot) {
        firstSnapshot = false;
        return;
      }

      snapshot.docChanges().forEach((change) => {
        // Whenever a new query is added to the sub collection
        if (change.type === 'added') {
          const data = change.doc.data();

          setNewState(currentState.list.concat([data]));
        }

        // Whenever the recently added in the sub collection is modified / changed
        if (change.type === 'modified') {
          const doc = change.doc.data();

          const updatedList = currentState.list.map((value: any) => {
            return value?.id === doc?.id ? doc : value;
          });

          setNewState(updatedList);
        }
      });
    });

    return () => {
      unsubscribe();
    };
  }, [currentState]);
};

// Add a new document with a generated id.
export const snapshotDocument = (route: string, currentState: any, setNewState: any, firstSnapshot = true) => {
  useEffect(() => {
    // Construct the Firestore reference to the specific document
    const docRef = doc(FIRESTORE_DB, route);

    // Listen for changes on the specific document
    const unsubscribe = onSnapshot(docRef, (snapshot) => {
      // Return first snapshot as that contains the initial document state. We only need the changes.
      if (firstSnapshot) {
        firstSnapshot = false;
        return;
      }

      // Handle document changes
      const data = snapshot.data();

      if (data) {
        setNewState({ ...currentState, data: data });
      }
    });

    return () => {
      unsubscribe();
    };
  }, [currentState, route]);
};
