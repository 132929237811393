import { useTranslation } from 'react-i18next';
import Header from './Header';
import Navigation from './Navigation';
import ToggleSettings from './ToggleSettings';
import Avatar from './Avatar';
import DisplayName from './DisplayName';
import { useEffect } from 'react';
import { FIREBASE_AUTH } from '../../../utils/firebase';
import { useAppSelector } from '../../../hooks/redux';

const LeftPanel = ({ children }: any) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user);

  return (
    <section className="left-panel">
      <Header />
      <Navigation />

      <div className="left-panel__user">
        <div className="left-panel__user-info">
          <div className="left-panel__user-avatar">
            <Avatar />
          </div>
          <DisplayName user_name={user?.firstname + ' ' + user?.lastname} email={user?.email} />
          <div className="left-panel__user-settings">
            <ToggleSettings />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LeftPanel;
