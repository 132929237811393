import { Divider, Grid, List, ListItemText, ListSubheader } from '@mui/material';
import React from 'react';
import OrganizationListItem from '../../organizations/OrganizationListItem';

interface Props {
  partnerOrganizations: any; // list of organizations attached to partner
}

function PartnerOrganization({ partnerOrganizations }: Props) {
  return (
    <Grid item xs={12} md={6} className="model-list">
      <List>
        <ListSubheader className={'operations-list-header'}>
          <ListItemText primary={'Organization Name'} className="list-header-elem" />
          <ListItemText primary={'Country'} className="list-header-elem" />
          <ListItemText primary={'ID'} className="list-header-elem" />
        </ListSubheader>
        {partnerOrganizations &&
          partnerOrganizations.map((item: any) => {
            return (
              <>
                <OrganizationListItem organization={item} />
                <Divider variant="middle" component="li" />
              </>
            );
          })}
      </List>
    </Grid>
  );
}

export default PartnerOrganization;
